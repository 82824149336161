<template>    
  <main class="bg-gray-200">
    <Toast :life="2000"/>
    <ConfirmDialog/>
    <div class="grid grid-cols-6">
      <keep-alive v-if="showSideMenu">
        <div v-if="$route.meta.menu === 'SideMenu'" class="col-span-1 bg-gray-900">
          <SideMenu />
        </div>
        <div v-else-if="$route.meta.menu === 'VideoMenu'" class="col-span-1 bg-gray-900">
          <VideoMenu />
        </div>
      </keep-alive>
      <div :class="{'col-span-5': showSideMenu, 'col-span-6': !showSideMenu, 'bg-black': ['playback_path', 'embed_path'].includes($route.meta.name)}" class="h-screen overflow-scroll">
        <router-view />
      </div>
    </div>
  </main>
  <bottom />
</template>

<script setup>
const SideMenu = defineAsyncComponent(() => import('./SideMenu.vue'));
const VideoMenu = defineAsyncComponent(() => import('./VideoMenu.vue'));
import NavBar from './_nav.vue';
import Bottom from './_footer.vue';
import ConfirmDialog from 'primevue/confirmdialog';
import { UserStore } from '@/front/stores/user_store.js';
const userStore = UserStore();
const route = useRoute();

const showSideMenu = computed(() => {
  return route.meta.auth && (route.meta.menu && userStore.present)  
})


onMounted(() => {
  userStore.fetch()
})

watch(
  () => route.meta,
  (newMeta) => {
    if (newMeta) {
      if (newMeta.auth && !userStore.present) {
        let str = '/login'
        let next = window.location.pathname + window.location.search
        if (next != '/') {
          str += '?return=' + next
        }
        window.location.href = str
      }
    }
  },
  { immediate: true }
);

</script>